import { Dispatch, SetStateAction } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { Icon } from '../../Icon/Icon';
import { format } from 'date-fns';
import { Button, Flex, Text } from '@chakra-ui/react';

export const CustomHeader: React.FC<
	ReactDatePickerCustomHeaderProps & {
		setIsYearPickerShow: Dispatch<SetStateAction<boolean>>;
	}
> = ({
	date,
	decreaseMonth,
	increaseMonth,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled,
	setIsYearPickerShow,
}) => {
	return (
		<Flex
			justifyContent={'space-between'}
			margin={'0 24px'}
			alignItems={'center'}
			paddingTop={'24px'}>
			<Flex justifyContent={'center'} gap={'10px'} alignItems={'center'}>
				<Text fontWeight={'700'}>{format(date, 'MMMM yyyy')}</Text>
				<Button
					padding={0}
					minWidth={0}
					onClick={() => setIsYearPickerShow(prev => !prev)}>
					<Icon name={'down-arrow'} width={'12px'} height={'16px'} />
				</Button>
			</Flex>

			<Flex>
				<Button
					onClick={decreaseMonth}
					disabled={prevMonthButtonDisabled}
					padding={0}
					minWidth={'24px'}>
					<Icon name={'left-arrow'} width={'24px'} height={'24px'} />
				</Button>
				<Button
					onClick={increaseMonth}
					disabled={nextMonthButtonDisabled}
					padding={0}
					minWidth={'24px'}>
					<Icon name={'right-arrow'} width={'24px'} height={'24px'} />
				</Button>
			</Flex>
		</Flex>
	);
};
