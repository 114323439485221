import React, { PropsWithChildren } from 'react';
import { Box, Button, Center, Flex, Input, Text } from '@chakra-ui/react';
import { Icon } from '../../../components';
import { inputFileStyles } from '../../../constants/input-styles';

export const UploadFiles: React.FC<PropsWithChildren> = () => {
	return (
		<Flex flexDirection="column" w={'100%'} gap={'48px'}>
			<Center>
				<form>
					<Flex flexDirection={'column'} gap={'40px'} minW={'422px'}>
						<Flex gap={'8px'} alignItems={'center'}>
							<Text>Date:</Text>
							<Input
								placeholder={'Enter date'}
								{...inputFileStyles}
								isDisabled={true}
							/>
							<Icon
								name={'calendar'}
								width={'24px'}
								height={'24px'}
							/>
						</Flex>
						<Flex gap={'8px'} alignItems={'center'}>
							<Text sx={{ textWrap: 'nowrap' }}>
								Input Directory:
							</Text>
							<Input
								placeholder={'Enter starting bin'}
								{...inputFileStyles}
								isDisabled={true}
							/>
						</Flex>
						<Flex gap={'8px'} alignItems={'center'}>
							<Text sx={{ textWrap: 'nowrap' }}>
								Starting bin:
							</Text>
							<Input
								placeholder={'Enter starting bin'}
								{...inputFileStyles}
								isDisabled={true}
							/>
							<Box width={'24px'}>
								<Icon
									name={'two-arrows'}
									width={'24px'}
									height={'24px'}
								/>
							</Box>
						</Flex>
						<Flex gap={'8px'} alignItems={'center'}>
							<Text sx={{ textWrap: 'nowrap' }}>
								Number of bins:
							</Text>
							<Input
								placeholder={'Enter number of bins'}
								{...inputFileStyles}
								isDisabled={true}
							/>
							<Box width={'24px'}>
								<Icon
									name={'two-arrows'}
									width={'24px'}
									height={'24px'}
								/>
							</Box>
						</Flex>
						<Flex gap={'32px'}>
							<Button variant={'secondary'}>Cancel</Button>
							<Button variant={'primary'}>Submit</Button>
						</Flex>
					</Flex>
				</form>
			</Center>
		</Flex>
	);
};
