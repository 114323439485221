export enum AppRoutes {
	SIGN_UP = 'sign_up',
	SIGN_IN = 'sign_in',
	FORGOT_PASSWORD = 'forgot_password',
	CREATE_NEW_PASSWORD = 'create_new_password',
	FINAL_OUTPUT = 'final_output',
	FINAL_REPORT = 'final_report',
	REPORT = 'report',
	UPLOAD_FILES = 'upload_files',
	PROFILE_SETTINGS = 'profile_settings',
	TESTS = 'tests',
	TEST = 'test',
	ANIMALS = 'animals',
	NOT_FOUND = 'not_found',
}
