import React, { PropsWithChildren } from 'react';
import {
	Flex,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from '@chakra-ui/react';
import { ProcessFiles, UploadFiles } from './components';

export const UploadFilesPage: React.FC<PropsWithChildren> = () => {
	return (
		<Tabs variant="custom" w={'100%'}>
			<Flex alignItems={'center'}>
				<TabList>
					<Tab w={'120px'}>
						Process <br />
						Files
					</Tab>
					<Tab w={'120px'}>
						Upload <br />
						Files
					</Tab>
				</TabList>
			</Flex>
			<TabPanels marginTop={'45px'}>
				<TabPanel>
					<ProcessFiles />
				</TabPanel>
				<TabPanel>
					<UploadFiles />
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
};
