import { AppRoutes } from './app-routes';

export const ROUTE_PATH: Record<AppRoutes, string> = {
	[AppRoutes.SIGN_UP]: '/',
	[AppRoutes.SIGN_IN]: '/sign-in',
	[AppRoutes.FORGOT_PASSWORD]: '/forgot-password',
	[AppRoutes.CREATE_NEW_PASSWORD]: '/create-new-password',
	[AppRoutes.FINAL_OUTPUT]: '/final-output',
	[AppRoutes.FINAL_REPORT]: '/final-report',
	[AppRoutes.REPORT]: '/report',
	[AppRoutes.UPLOAD_FILES]: '/upload-files',
	[AppRoutes.PROFILE_SETTINGS]: '/profile-settings',
	[AppRoutes.TESTS]: '/tests',
	[AppRoutes.TEST]: '/test',
	[AppRoutes.ANIMALS]: '/animals',
	[AppRoutes.NOT_FOUND]: '/404',
};
