import React, { useState } from 'react';
import {
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { CustomDatePicker } from '../../components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alerter } from '../../utils';

interface AddAnimalModalProps {
	isOpen: boolean;
	onClose: () => void;
	onAddWeight: (wtDate: string) => void;
}
interface AddStartWeightValues {
	wtDate: string;
}

export const AddStartWeightModal: React.FC<AddAnimalModalProps> = ({
	isOpen,
	onClose,
	onAddWeight,
}) => {
	const [isCalenderOpen, setIsCalenderOpen] = useState(false);
	const toggleCalender = (open: boolean) => setIsCalenderOpen(open);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm<AddStartWeightValues>();

	const onSubmit: SubmitHandler<AddStartWeightValues> = data => {
		onAddWeight(data.wtDate);
		onClose();
		Alerter.success('New Weight # has been added successfully');
	};

	return (
		<Modal
			blockScrollOnMount={false}
			isOpen={isOpen}
			onClose={onClose}
			autoFocus={false}>
			<ModalOverlay />
			<ModalContent
				minW={'538px'}
				minH={'288px'}
				alignItems={'center'}
				borderRadius={'12px'}>
				<ModalHeader
					display={'flex'}
					justifyContent={'center'}
					p={'32px 32px 24px'}>
					<Text variant={'B03'} color={'dark'}>
						Add Start Weight
					</Text>
				</ModalHeader>
				<form onSubmit={handleSubmit(onSubmit)}>
					<CustomDatePicker
						toggleCalender={toggleCalender}
						label={'Wt Date'}
						showTime={false}
						errorMsg={errors.wtDate?.message as string}
						{...register('wtDate')}
						setValue={setValue}
					/>
					<Flex
						gap={'24px'}
						p={'32px'}
						minW={'422px'}
						marginTop={isCalenderOpen ? '324px' : 0}>
						<Button variant={'secondary'} onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant={'primary'}
							type={'submit'}
							isDisabled={true}>
							Add
						</Button>
					</Flex>
				</form>
			</ModalContent>
		</Modal>
	);
};
