import React, { PropsWithChildren } from 'react';
import { Flex } from '@chakra-ui/react';
import { HeaderLayout } from './HeaderLayout';
import { MainLayout } from './MainLayout';

export const Layout: React.FC<PropsWithChildren> = () => {
	return (
		<Flex flexDirection="column">
			<HeaderLayout />
			<MainLayout />
		</Flex>
	);
};
