import {
	chakra,
	Flex,
	Box,
	useCheckbox,
	UseCheckboxProps,
} from '@chakra-ui/react';
import React from 'react';

export const CustomCheckbox = React.forwardRef<
	HTMLInputElement,
	UseCheckboxProps
>((props, ref) => {
	const { state, getCheckboxProps, getInputProps, htmlProps } = useCheckbox({
		...props,
	});

	return (
		<chakra.label display="flex" cursor="pointer" {...htmlProps}>
			<input {...getInputProps()} hidden={true} ref={ref} />
			<Flex
				alignItems="center"
				justifyContent="center"
				border="none"
				backgroundColor="lightGray"
				w={4}
				h={4}
				rounded="full"
				{...getCheckboxProps()}>
				{state.isChecked ? (
					<Box w={2.5} h={2.5} bg="green" rounded="full" />
				) : null}
			</Flex>
		</chakra.label>
	);
});

CustomCheckbox.displayName = 'CustomCheckbox';
