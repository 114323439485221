import React, { PropsWithChildren } from 'react';
import {
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';

const data = [
	{
		normals: 'BROCI (normals)',
		numFeed: '# of Feed Events',
		totalDuration: 'Total Duration',
		tagHitCount: 'Tag Hit COUNT',
	},
	{
		normals: 'BROCI (normals)',
		numFeed: '# of Feed Events',
		totalDuration: 'Total Duration',
		tagHitCount: 'Tag Hit COUNT',
	},
	{
		normals: 'BROCI (normals)',
		numFeed: '# of Feed Events',
		totalDuration: 'Total Duration',
		tagHitCount: 'Tag Hit COUNT',
	},
	{
		normals: 'BROCI (normals)',
		numFeed: '# of Feed Events',
		totalDuration: 'Total Duration',
		tagHitCount: 'Tag Hit COUNT',
	},
	{
		normals: 'BROCI (normals)',
		numFeed: '# of Feed Events',
		totalDuration: 'Total Duration',
		tagHitCount: 'Tag Hit COUNT',
	},
	{
		normals: 'BROCI (normals)',
		numFeed: '# of Feed Events',
		totalDuration: 'Total Duration',
		tagHitCount: 'Tag Hit COUNT',
	},
	{
		normals: 'BROCI (normals)',
		numFeed: '# of Feed Events',
		totalDuration: 'Total Duration',
		tagHitCount: 'Tag Hit COUNT',
	},
	{
		normals: 'BROCI (normals)',
		numFeed: '# of Feed Events',
		totalDuration: 'Total Duration',
		tagHitCount: 'Tag Hit COUNT',
	},
	{
		normals: 'BROCI (normals)',
		numFeed: '# of Feed Events',
		totalDuration: 'Total Duration',
		tagHitCount: 'Tag Hit COUNT',
	},
	{
		normals: 'BROCI (normals)',
		numFeed: '# of Feed Events',
		totalDuration: 'Total Duration',
		tagHitCount: 'Tag Hit COUNT',
	},
];

export const TestBehavior: React.FC<PropsWithChildren> = () => {
	return (
		<TableContainer
			backgroundColor={'light'}
			boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
			borderRadius={'12px'}
			padding={'16px'}>
			<Table variant="custom" backgroundColor={'white'}>
				<Thead>
					<Tr>
						<Th>BROCI (normals)</Th>
						<Th># of Feed Events</Th>
						<Th>Total Duration</Th>
						<Th>Tag Hit COUNT</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, index) => (
						<Tr key={index}>
							<Td>{item.normals}</Td>
							<Td>{item.numFeed}</Td>
							<Td>{item.totalDuration}</Td>
							<Td>{item.tagHitCount}</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
