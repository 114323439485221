import React, { PropsWithChildren } from 'react';
import {
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { DeleteAnimalPopover } from '../../../modals';
import { colors } from '../../../theme/colors';

const data = [
	{
		testDuration: '52',
		averageDailyGain: '3,03',
		adgRatio: '93',
		asFed: '38,23',
		dmi: '20,15',
		dmiRatio: '116',
		conversionDmi: '6,65',
		dailyCog: '$0,60',
		cogRatio: '121',
	},
	{
		testDuration: '52',
		averageDailyGain: '3,03',
		adgRatio: '93',
		asFed: '38,23',
		dmi: '20,15',
		dmiRatio: '116',
		conversionDmi: '6,65',
		dailyCog: '$0,60',
		cogRatio: '121',
	},
	{
		testDuration: '52',
		averageDailyGain: '3,03',
		adgRatio: '93',
		asFed: '38,23',
		dmi: '20,15',
		dmiRatio: '116',
		conversionDmi: '6,65',
		dailyCog: '$0,60',
		cogRatio: '121',
	},
	{
		testDuration: '52',
		averageDailyGain: '3,03',
		adgRatio: '93',
		asFed: '38,23',
		dmi: '20,15',
		dmiRatio: '116',
		conversionDmi: '6,65',
		dailyCog: '$0,60',
		cogRatio: '121',
	},
	{
		testDuration: '52',
		averageDailyGain: '3,03',
		adgRatio: '93',
		asFed: '38,23',
		dmi: '20,15',
		dmiRatio: '116',
		conversionDmi: '6,65',
		dailyCog: '$0,60',
		cogRatio: '121',
	},
	{
		testDuration: '52',
		averageDailyGain: '3,03',
		adgRatio: '93',
		asFed: '38,23',
		dmi: '20,15',
		dmiRatio: '116',
		conversionDmi: '6,65',
		dailyCog: '$0,60',
		cogRatio: '121',
	},
	{
		testDuration: '52',
		averageDailyGain: '3,03',
		adgRatio: '93',
		asFed: '38,23',
		dmi: '20,15',
		dmiRatio: '116',
		conversionDmi: '6,65',
		dailyCog: '$0,60',
		cogRatio: '121',
	},
	{
		testDuration: '52',
		averageDailyGain: '3,03',
		adgRatio: '93',
		asFed: '38,23',
		dmi: '20,15',
		dmiRatio: '116',
		conversionDmi: '6,65',
		dailyCog: '$0,60',
		cogRatio: '121',
	},
	{
		testDuration: '52',
		averageDailyGain: '3,03',
		adgRatio: '93',
		asFed: '38,23',
		dmi: '20,15',
		dmiRatio: '116',
		conversionDmi: '6,65',
		dailyCog: '$0,60',
		cogRatio: '121',
	},
	{
		testDuration: '52',
		averageDailyGain: '3,03',
		adgRatio: '93',
		asFed: '38,23',
		dmi: '20,15',
		dmiRatio: '116',
		conversionDmi: '6,65',
		dailyCog: '$0,60',
		cogRatio: '121',
	},
	{
		testDuration: '52',
		averageDailyGain: '3,03',
		adgRatio: '93',
		asFed: '38,23',
		dmi: '20,15',
		dmiRatio: '116',
		conversionDmi: '6,65',
		dailyCog: '$0,60',
		cogRatio: '121',
	},
	{
		testDuration: '52',
		averageDailyGain: '3,03',
		adgRatio: '93',
		asFed: '38,23',
		dmi: '20,15',
		dmiRatio: '116',
		conversionDmi: '6,65',
		dailyCog: '$0,60',
		cogRatio: '121',
	},
];

export const FinalAnalysis: React.FC<PropsWithChildren> = () => {
	return (
		<TableContainer
			backgroundColor={'light'}
			boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
			borderRadius={'12px'}
			padding={'16px'}>
			<Table variant="custom" backgroundColor={'white'}>
				<Thead>
					<Tr>
						<Th>Actions</Th>
						<Th>Test Duration</Th>
						<Th>Average Daily Gain</Th>
						<Th>ADG Ratio</Th>
						<Th>Test ADC lbs (as fed)</Th>
						<Th>Test ADC lbs (DMI)</Th>
						<Th>ADC (DMI) Ratio</Th>
						<Th>Conversion DMI</Th>
						<Th>AVG Daily COG/lb of Gain</Th>
						<Th>COG Ratio</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, index) => (
						<Tr key={index}>
							<Td>
								<DeleteAnimalPopover
									color={`${colors.error}`}
								/>
							</Td>
							<Td>{item.testDuration}</Td>
							<Td>{item.averageDailyGain}</Td>
							<Td>{item.adgRatio}</Td>
							<Td>{item.asFed}</Td>
							<Td>{item.dmi}</Td>
							<Td>{item.dmiRatio}</Td>
							<Td>{item.conversionDmi}</Td>
							<Td>{item.dailyCog}</Td>
							<Td>{item.cogRatio}</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
