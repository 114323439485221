import { ComponentStyleConfig } from '@chakra-ui/react';

export const Table: ComponentStyleConfig = {
	variants: {
		custom: {
			table: {
				width: '100%',
			},
			thead: {
				tr: {
					borderBottom: '1px solid',
					borderBottomColor: 'lightGray',
				},
				th: {
					fontSize: '14px',
					fontWeight: '700',
					whiteSpace: 'normal',
					overflowWrap: 'break-word',
					textTransform: 'capitalize',
					paddingX: '12px',
				},
			},
			tbody: {
				tr: {
					td: {
						fontSize: '14px',
						fontWeight: '400',
						overflowWrap: 'break-word',
						padding: '12px',
					},
					borderBottom: '1px solid',
					borderBottomColor: 'lightGray',
					_last: {
						borderBottom: 'none',
					},
				},
			},
		},
	},
};
