import { ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

interface INavLinkItem {
	name: ReactNode;
	icon: ReactNode;
	path: string;
}
const afterStyles = {
	color: 'white',
	backgroundColor: 'primary',
	borderRadius: ' 0 12px 12px 0',
};

export const NavLinkItem: React.FC<INavLinkItem> = ({ name, path, icon }) => {
	return (
		<NavLink to={path}>
			{({ isActive }) => (
				<Flex
					sx={isActive ? afterStyles : {}}
					transition={'.2s ease'}
					alignItems={'center'}
					position={'relative'}
					w={'100%'}
					gap={'8px'}
					p={'8px'}>
					{icon}
					{name}
				</Flex>
			)}
		</NavLink>
	);
};
