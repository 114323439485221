import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const getFormattedViewDate = (date: string) => {
	const currentDate = dayjs();
	const inputDateTime = dayjs(date);

	const formattedTime = inputDateTime.format('hh:mm A');
	const formattedDate = inputDateTime.format('MMM D, YYYY');
	if (!inputDateTime.isSame(currentDate, 'day')) {
		return { time: formattedTime, date: formattedDate };
	}

	return { time: formattedTime };
};

export const getTimePassedActivity = (date: string) => {
	const now = dayjs();
	const pastDate = dayjs(date);

	const diffSeconds = now.diff(pastDate, 'second');
	const diffMinutes = now.diff(pastDate, 'minute');
	const diffHours = now.diff(pastDate, 'hour');
	const diffDays = now.diff(pastDate, 'day');
	const diffMonths = now.diff(pastDate, 'month');
	const diffYears = now.diff(pastDate, 'year');

	if (diffSeconds < 60) {
		return `${diffSeconds} s`;
	}
	if (diffMinutes < 60) {
		return `${diffMinutes} min`;
	}
	if (diffHours < 24) {
		return `${diffHours} hr`;
	}
	if (diffDays < 30) {
		return `${diffDays} d`;
	}
	if (diffMonths < 12) {
		return `${diffMonths} m`;
	}

	return `${diffYears} y`;
};

export const isNewMonth = (date1: string, date2: string) =>
	!dayjs(date1).isSame(dayjs(date2), 'month');

export const getMonthNameByDate = (date: string) => {
	const currentDate = dayjs();
	const inputtedDate = dayjs(date);

	const formattedDate = inputtedDate.format('MMMM');

	return currentDate.month() === inputtedDate.month()
		? 'This Month'
		: formattedDate;
};
