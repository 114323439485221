const variantFlushed = () => ({
	field: {
		boxShadow: 'none !important',
		_focus: {
			boxShadow: 'none',
			borderColor: 'transparent',
			borderBottom: '1px solid #010101',
		},
	},
});

const variantOutline = () => ({
	field: {
		boxShadow: 'none !important',
		_focus: {
			boxShadow: 'none',
			borderColor: 'transparent',
			border: '2px solid #175D00',
		},
	},
});

export const Input = {
	baseStyle: {},
	sizes: {},
	variants: {
		flushed: variantFlushed,
		outline: variantOutline,
	},
	defaultProps: {},
};
