import React, { PropsWithChildren, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Text,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Box,
	Button,
	Flex,
} from '@chakra-ui/react';
import { Icon } from '../../components';
import {
	DailyBehavior,
	FinalAnalysis,
	TestBehavior,
	AnimalInfo,
} from './components';
export const Report: React.FC<PropsWithChildren> = () => {
	const navigate = useNavigate();
	const [tabIndex, setTabIndex] = useState(0);
	const tabListRef = useRef<HTMLDivElement | null>(null);

	const isLeftDisabled = tabIndex === 0;
	const isRightDisabled = tabIndex === 6;

	const handleBack = () => navigate(-1);

	const handleLeftClick = () => {
		if (tabIndex > 0) {
			setTabIndex(prev => prev - 1);
			scrollToTab(tabIndex);
		}
	};

	const handleRightClick = () => {
		if (tabIndex < 6) {
			setTabIndex(prev => prev + 1);
			scrollToTab(tabIndex);
		}
	};
	const scrollToTab = (index: number) => {
		const tabList = tabListRef.current;
		if (tabList) {
			const activeTab = tabList.children[index] as HTMLElement;
			if (activeTab) {
				activeTab.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
					inline: 'center',
				});
			}
		}
	};
	return (
		<Box>
			<Tabs variant="custom" index={tabIndex}>
				<Flex alignItems={'center'} gap={'16px'}>
					<Button
						onClick={handleBack}
						minWidth={'80px'}
						color={'primary'}
						p={'12px'}
						paddingLeft={'0px'}
						gap={'8px'}
						marginRight={'16px'}>
						<Icon
							name={'left-arrow'}
							width={'24px'}
							height={'24px'}
						/>
						Back
					</Button>
					<Text minWidth={'177px'} variant={'B03'} color={'dark'}>
						Report #8
					</Text>
					<Flex alignItems={'center'} gap={'16px'} minWidth={'636px'}>
						<Button
							px={0}
							onClick={handleLeftClick}
							isDisabled={isLeftDisabled}
							variant={'primary'}
							width={'24px'}
							height={'24px'}
							sx={{
								_disabled: {
									bg: 'gray',
									cursor: 'not-allowed',
									_hover: {
										bg: 'gray',
										cursor: 'not-allowed',
									},
								},
							}}
							borderRadius={'50%'}>
							<Icon
								color="white"
								name={'left-arrow'}
								width={'24px'}
								height={'24px'}
							/>
						</Button>
						<TabList
							overflow={'hidden'}
							maxWidth={'496px'}
							ref={tabListRef}>
							<Tab
								sx={{
									borderLeft: 'none',
								}}>
								Animal information
							</Tab>
							<Tab>Warmup (minimum 1 week)</Tab>
							<Tab>Daily Consumption (Kg)</Tab>
							<Tab>what name(?)</Tab>
							<Tab>Final Analysis</Tab>
							<Tab>Daily Behavior</Tab>
							<Tab
								sx={{
									borderRight: 'none',
								}}>
								Test Behavior (Daily AVG)
							</Tab>
						</TabList>
						<Button
							variant={'primary'}
							onClick={handleRightClick}
							isDisabled={isRightDisabled}
							sx={{
								_disabled: {
									bg: 'gray',
									cursor: 'not-allowed',
									_hover: {
										bg: 'gray',
										cursor: 'not-allowed',
									},
								},
							}}
							width={'24px'}
							height={'24px'}
							borderRadius={'50%'}
							px={0}>
							<Icon
								name={'right-arrow'}
								color="white"
								width={'24px'}
								height={'24px'}
							/>
						</Button>
					</Flex>
					<Button
						variant={'primary'}
						height={'48px'}
						maxWidth={'195px'}
						gap={'8px'}
						fontWeight={'700'}>
						{' '}
						Refresh
						<Icon
							color={'#FFFFF'}
							name={'refresh'}
							width={'16px'}
							height={'16px'}
						/>
					</Button>
				</Flex>
				<TabPanels marginTop={'32px'}>
					<TabPanel>
						{' '}
						<AnimalInfo />
					</TabPanel>
					<TabPanel>
						<Text>Warmup content</Text>
					</TabPanel>
					<TabPanel>
						<Text>Daily Consumption content</Text>
					</TabPanel>
					<TabPanel>
						<Text>What name content</Text>
					</TabPanel>
					<TabPanel>
						<FinalAnalysis />
					</TabPanel>
					<TabPanel>
						<DailyBehavior />
					</TabPanel>
					<TabPanel>
						<TestBehavior />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};
