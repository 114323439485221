export const inputStyles = {
	lineHeight: '24px',
	fontSize: '16px',
	fontWeight: 400,
	color: 'dark',
	borderRadius: '0',
	border: 'none',
	padding: '12px 0',
	borderBottom: '1px solid #010101',
	_placeholder: {
		color: 'gray',
		fontWeight: 500,
	},
};

export const inputSearchStyles = {
	lineHeight: '20px',
	fontSize: '12px',
	fontWeight: 400,
	color: 'dark',
	borderRadius: '8px',
	border: '1px solid #175D00',
	padding: '12px 8px 12px 32px',
	_placeholder: {
		color: 'gray',
		fontWeight: 500,
	},
};

export const inputFileStyles = {
	lineHeight: '20px',
	fontSize: '12px',
	fontWeight: 400,
	color: 'dark',
	borderRadius: '8px',
	border: '1px solid lightGray',
	padding: '8px',
	_placeholder: {
		color: 'gray',
		fontWeight: 400,
	},
};
