import { ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
	baseStyle: {
		color: 'primary',
		fontSize: '14px',
		lineHeight: '22px',
		fontWeight: 400,
	},
	variants: {
		B01: {
			fontSize: '14px',
			lineHeight: '22px',
			fontWeight: 500,
		},
		B02: {
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: 400,
		},

		B03: {
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: 600,
		},
		B04: {
			fontSize: '40px',
			lineHeight: '56px',
			fontWeight: 500,
		},
		B05: {
			fontSize: '32px',
			lineHeight: '45px',
			fontWeight: 500,
		},
		B06: {
			fontWeight: 600,
		},
		B07: {
			_hover: {
				backgroundColor: 'primary',
				color: 'light',
			},
		},
	},
};
