import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTE_PATH } from '../../../constants/route-paths';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
	Button,
	Flex,
	Text,
	Link,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';

import { InputText, InputPassword, CustomCheckbox } from '../../../components';
import { colors } from '../../../theme/colors';
import { inputStyles } from '../../../constants/input-styles';
import { VerifyEmailModal } from '../../../modals';

interface SignUpFormValues {
	username: string;
	email: string;
	password: string;
	confirmPassword: string;
	phone: string;
	isAgree: boolean;
	code: string;
}

export const SignUp = () => {
	const [isVerified, setIsVerified] = useState<boolean>(false);
	const [isVerifyButton, setIsVerifyButton] = useState<boolean>(false);
	const [timer, setTimer] = useState<number | null>(null);
	const { isOpen, onClose, onOpen } = useDisclosure();

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm<SignUpFormValues>();
	const onSubmit: SubmitHandler<SignUpFormValues> = data => {
		console.log(data);
	};

	const watchFields = watch([
		'username',
		'email',
		'password',
		'confirmPassword',
		'phone',
		'isAgree',
	]);
	const isDisabledCode = !watchFields.every(field => field);

	const handleSendCode = () => {
		console.log('code send');
		onOpen();
		setIsVerifyButton(true);
	};

	const handleVerify = () => {
		console.log('verified');
		setIsVerified(true);
		setTimer(20);
	};

	useEffect(() => {
		let countdown: NodeJS.Timeout;
		if (timer && timer > 0) {
			countdown = setTimeout(() => {
				setTimer(prevTimer => (prevTimer ? prevTimer - 1 : 0));
			}, 1000);
		}
		if (timer === 0) {
			setIsVerifyButton(false);
		}
		return () => clearTimeout(countdown);
	}, [timer]);
	return (
		<Flex
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'center'}
			gap={'40px'}
			width={'422px'}>
			<Flex flexDirection={'column'} alignItems={'center'} gap={'16px'}>
				<Text color={'dark'} variant={'B04'}>
					Create new account
				</Text>
				<Text color={'dark'} variant={'B02'}>
					Sign up an account
				</Text>
			</Flex>
			<VStack spacing={'24px'} width={'100%'}>
				<InputText
					{...register('username', {
						required: 'Username is required',
					})}
					errorMsg={errors.username?.message as string}
					label={'Your username'}
					variant={'flushed'}
					placeholder={'Enter your username'}
					autoComplete={''}
				/>
				<InputText
					{...register('email', {
						required: 'Email is required',
						pattern: {
							value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
							message: 'Invalid email address',
						},
					})}
					errorMsg={errors.email?.message as string}
					label={'Your email'}
					variant={'flushed'}
					placeholder={'Enter your email'}
					autoComplete={'email'}
				/>
				<Flex width={'100%'}>
					<InputText
						label={'Verification code'}
						variant={'flushed'}
						placeholder={'Enter the verification code'}
						errorMsg={errors.phone?.message as string}
						isDisabled={isDisabledCode}
						autoComplete={''}
						{...register('code', {
							pattern: {
								value: /^[0-9]+$/,
								message:
									'Verification code must be digits only',
							},
						})}
					/>
					{isVerifyButton ? (
						<Button
							{...inputStyles}
							isDisabled={isDisabledCode}
							onClick={handleVerify}
							minHeight={'61px'}
							color={isDisabledCode ? 'gray' : 'primary'}
							minWidth={'124px'}
							borderLeft={`1px solid ${isDisabledCode ? colors.dark : colors.primary}`}
							backgroundColor={
								isDisabledCode
									? undefined
									: `${colors.secondary}`
							}>
							{timer && timer > 0
								? `${timer}s to resend`
								: 'Verify'}
						</Button>
					) : (
						<Button
							{...inputStyles}
							isDisabled={isDisabledCode}
							onClick={handleSendCode}
							minHeight={'61px'}
							color={isDisabledCode ? 'gray' : 'primary'}
							minWidth={'124px'}
							borderLeft={`1px solid ${isDisabledCode ? colors.dark : colors.primary}`}
							backgroundColor={
								isDisabledCode
									? undefined
									: `${colors.secondary}`
							}>
							Send code
						</Button>
					)}
				</Flex>
				<InputPassword
					label={'Your password'}
					variant={'flushed'}
					placeholder={'Enter your password'}
					showRules={false}
					errorMsg={errors.password?.message as string}
					{...register('password', {
						required: 'Password is required',
					})}
				/>
				<InputPassword
					label={'Confirm password'}
					variant={'flushed'}
					placeholder={'Enter your password'}
					showRules={false}
					errorMsg={errors.confirmPassword?.message as string}
					{...register('confirmPassword', {
						required: 'Confirm password is required',
						validate: value =>
							value === watchFields[2] ||
							'Passwords do not match',
					})}
				/>
				<InputText
					label={'Your phone'}
					variant={'flushed'}
					placeholder={'Enter your phone'}
					errorMsg={errors.phone?.message as string}
					autoComplete={''}
					{...register('phone', {
						required: 'Enter your phone',
						pattern: {
							value: /^[0-9]+$/,
							message: 'Phone number must be digits only',
						},
					})}
				/>
			</VStack>
			<Flex justifyContent={'start'} width={'100%'} gap={'8px'}>
				<Controller
					name="isAgree"
					control={control}
					render={({ field: { ref, onBlur, onChange, value } }) => (
						<CustomCheckbox
							ref={ref}
							isChecked={value}
							onBlur={onBlur}
							onChange={e => onChange(e.target.checked)}
						/>
					)}
				/>
				<Text color={'dark'} width={'100%'} fontSize={'13px'}>
					I’ve read and agreed to{' '}
					<Link as={RouterLink} to={'#'} color={`${colors.primary}`}>
						User Agreement
					</Link>{' '}
					and{' '}
					<Link as={RouterLink} to={'#'} color={`${colors.primary}`}>
						Privacy Policy
					</Link>
				</Text>
			</Flex>
			<Button
				variant={'primary'}
				isDisabled={!isVerified}
				onClick={handleSubmit(onSubmit)}>
				Sign Up
			</Button>
			<Flex gap={'8px'}>
				<Text color={'dark'}>Already have an account?</Text>
				<Link as={RouterLink} to={ROUTE_PATH.sign_in}>
					<Text fontWeight={'700'}>Log in</Text>
				</Link>
			</Flex>
			<VerifyEmailModal isOpen={isOpen} onClose={onClose} />
		</Flex>
	);
};
