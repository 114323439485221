import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { InputText } from '../../../components';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from '../../../constants/route-paths';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ForgotPasswordFormValues } from '../types';

export const ForgotPassword = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm<ForgotPasswordFormValues>();

	const onSubmit: SubmitHandler<ForgotPasswordFormValues> = data => {
		console.log(data);
	};
	const watchFields = watch(['email']);
	const isDisabled = !watchFields.every(field => field);
	return (
		<Flex
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'center'}
			gap={'40px'}
			width={'422px'}>
			<Flex flexDirection={'column'} alignItems={'center'} gap={'16px'}>
				<Text color={'dark'} variant={'B04'}>
					Forgot password
				</Text>
				<Text color={'dark'} variant={'B02'} textAlign={'center'}>
					Please enter your email, so we could send you a
					<br /> password reset linkt
				</Text>
			</Flex>
			<VStack width={'100%'}>
				<InputText
					{...register('email', {
						required: 'Email is required',
						pattern: {
							value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
							message: 'Invalid email address',
						},
					})}
					errorMsg={errors.email?.message as string}
					label={'Your email'}
					variant={'flushed'}
					placeholder={'Enter your email'}
					autoComplete={'email'}
				/>
			</VStack>
			<Button
				variant={'primary'}
				isDisabled={isDisabled}
				onClick={handleSubmit(onSubmit)}>
				Send
			</Button>
			<Flex gap={'8px'}>
				<Text color={'dark'}>Don’t have an account?</Text>
				<Link to={ROUTE_PATH.sign_up}>
					<Text fontWeight={'700'}>Sign Up</Text>
				</Link>
			</Flex>
		</Flex>
	);
};
