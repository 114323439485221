import { extendTheme, theme as base } from '@chakra-ui/react';
import { colors } from './colors';
import { Button, Heading, Text, Input, Tabs, Table } from './components';

export const theme = extendTheme({
	colors,
	fonts: {
		body: `'Poppins', ${base.fonts.body}`,
	},
	components: {
		Text,
		Button,
		Heading,
		Input,
		Tabs,
		Table,
	},
});
