import React from 'react';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { Icon } from '../../components';
import { shadows } from '../../theme/shadows';

interface ChangeAvatarModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const LogOutModal: React.FC<ChangeAvatarModalProps> = ({
	isOpen,
	onClose,
}) => {
	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				minH={'288px'}
				maxW={'296px'}
				borderRadius={'24px'}
				border={'1px solid #175D00'}
				boxShadow={shadows.dark}>
				<ModalHeader
					display={'flex'}
					justifyContent={'space-between'}
					p={'32px 32px 24px'}
					borderBottom={'1px solid #ECECEC'}>
					<Text variant={'B03'}>
						Are you sure you want to log out from your account?
					</Text>
				</ModalHeader>
				<Flex flexDirection={'column'} gap={'24px'} p={'32px'}>
					<Button variant={'primary'} onClick={onClose}>
						Log Out
						<Box ml={'8px'}>
							<Icon
								name={'log-out'}
								width="24px"
								height="24px"
								color={'secondary'}
							/>
						</Box>
					</Button>
					<Button variant={'secondary'} onClick={onClose}>
						Cancel
					</Button>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
