import React from 'react';
import {
	InputProps,
	InputGroup,
	InputLeftElement,
	FormControl,
	FormControlProps,
	Input,
} from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { inputSearchStyles } from '../../constants/input-styles';

interface ISearchBar extends InputProps {
	formControlProps?: FormControlProps;
}

export const SearchBar: React.FC<ISearchBar> = ({
	formControlProps,
	...rest
}) => {
	return (
		<FormControl {...formControlProps}>
			<InputGroup bg="inherit" w={'100%'}>
				<InputLeftElement h="100%">
					<Icon name={'search'} width={'16px'} height={'16px'} />
				</InputLeftElement>
				<Input
					w="full"
					type="text"
					variant={'outline'}
					{...inputSearchStyles}
					{...rest}
				/>
			</InputGroup>
		</FormControl>
	);
};
