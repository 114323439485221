import React from 'react';
import {
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import {
	CustomCheckbox,
	InputText,
	CustomSelect,
	CustomDatePicker,
} from '../../components';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

interface AddAnimalModalProps {
	isOpen: boolean;
	onClose: () => void;
}
interface AddAnimalFormValues {
	ranchTag: string;
	regNumber: string;
	birthDate: string;
	sex: string;
	eid: string;
	dna: string;
	sire: string;
	dam: string;
	eidFull: string;
	eidTruncated: string;
	eidNotes: string;
	contemporaryGroup: string;
	outOrSickDays?: boolean;
	out?: boolean;
	sick?: boolean;
	moreInfo?: string;
}

export const AddAnimalModal: React.FC<AddAnimalModalProps> = ({
	isOpen,
	onClose,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
	} = useForm<AddAnimalFormValues>();
	const onSubmit: SubmitHandler<AddAnimalFormValues> = data => {
		console.log(data);
	};
	const watchFields = watch([
		'birthDate',
		'ranchTag',
		'regNumber',
		'sex',
		'dna',
		'sire',
		'dam',
		'eid',
		'eidFull',
		'eidTruncated',
		'eidNotes',
		'contemporaryGroup',
	]);
	const isOutOrSickDays = watch('outOrSickDays');
	const isDisabled = !watchFields.every(field => field);

	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent minH={'472px'} minW={'486'} borderRadius={'24px'}>
				<ModalHeader
					display={'flex'}
					justifyContent={'center'}
					p={'32px 32px 24px'}>
					<Text variant={'B03'} color={'dark'}>
						Add Animal
					</Text>
				</ModalHeader>
				<Flex flexDirection={'column'} gap={'40px'} padding={'32px'}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Flex flexDirection={'column'} gap={'40px'}>
							<VStack spacing={'24px'}>
								<InputText
									label={'Ranch Tag'}
									variant={'flushed'}
									placeholder={'Enter Ranch Tag'}
									errorMsg={
										errors.ranchTag?.message as string
									}
									{...register('ranchTag', {
										required: 'Ranch Tag  is required',
									})}></InputText>{' '}
								<InputText
									label={'EID'}
									variant={'flushed'}
									placeholder={'Enter EID'}
									errorMsg={errors.eid?.message as string}
									{...register('eid', {
										required: 'EID is required',
									})}></InputText>{' '}
								<InputText
									label={'Reg #'}
									variant={'flushed'}
									placeholder={'Enter Reg #'}
									errorMsg={
										errors.regNumber?.message as string
									}
									{...register('regNumber', {
										required: 'Reg # is required',
									})}></InputText>
								<CustomDatePicker
									label={'Birth Date'}
									errorMsg={
										errors.birthDate?.message as string
									}
									{...register('birthDate', {
										required: 'Birth Date is required',
									})}
								/>
								<CustomSelect
									label={'Sex'}
									options={[{ label: 'B', value: 'B' }]}
									zIndex={3}
									placeholder={'Choose sex'}
									errorMsg={
										errors.sex?.message as string
									}></CustomSelect>
								<CustomSelect
									label={'DNA collected?'}
									options={[
										{ label: 'Yes', value: 'yes' },
										{ label: 'No', value: 'no' },
									]}
									placeholder={'Choose DNA collected'}
									errorMsg={
										errors.dna?.message as string
									}></CustomSelect>
								<InputText
									label={'Sire'}
									variant={'flushed'}
									placeholder={'Enter Sire'}
									errorMsg={errors.sire?.message as string}
									{...register('sire', {
										required: 'Sire is required',
									})}></InputText>
								<InputText
									label={'Dam'}
									variant={'flushed'}
									placeholder={'Enter Dam'}
									errorMsg={errors.dam?.message as string}
									{...register('dam', {
										required: 'Dam is required',
									})}></InputText>
								<InputText
									label={'EID Full'}
									variant={'flushed'}
									placeholder={'Enter EID Full'}
									errorMsg={errors.eidFull?.message as string}
									{...register('eidFull', {
										required: 'EID Full is required',
									})}></InputText>
								<InputText
									label={'EID Truncated'}
									variant={'flushed'}
									placeholder={'Enter EID Truncated'}
									errorMsg={
										errors.eidTruncated?.message as string
									}
									{...register('eidTruncated', {
										required: 'EID Truncated is required',
									})}></InputText>
								<InputText
									label={'EID Notes'}
									variant={'flushed'}
									placeholder={'Enter EID Notes'}
									errorMsg={
										errors.eidNotes?.message as string
									}
									{...register('eidNotes', {
										required: 'EID Notes is required',
									})}></InputText>
								<CustomSelect
									label={'Contemporary Group'}
									options={[
										{ label: 'Natural', value: 'natural' },
									]}
									placeholder={'Choose Contemporary Group'}
									errorMsg={
										errors.contemporaryGroup
											?.message as string
									}></CustomSelect>
								<Flex
									justifyContent={'start'}
									width={'100%'}
									gap={'8px'}>
									<Controller
										name="outOrSickDays"
										control={control}
										render={({
											field: {
												ref,
												onBlur,
												onChange,
												value,
											},
										}) => (
											<CustomCheckbox
												ref={ref}
												isChecked={value}
												onBlur={onBlur}
												onChange={e =>
													onChange(e.target.checked)
												}
											/>
										)}
									/>
									<Text
										color={'dark'}
										width={'100%'}
										fontSize={'13px'}>
										Out or Sick Days
									</Text>
								</Flex>
								{isOutOrSickDays ? (
									<>
										<Flex
											justifyContent={'start'}
											width={'100%'}
											gap={'8px'}>
											<Controller
												name="out"
												control={control}
												render={({
													field: {
														ref,
														onBlur,
														onChange,
														value,
													},
												}) => (
													<CustomCheckbox
														ref={ref}
														isChecked={value}
														onBlur={onBlur}
														onChange={e =>
															onChange(
																e.target
																	.checked,
															)
														}
													/>
												)}
											/>
											<Text
												color={'dark'}
												width={'100%'}
												fontSize={'13px'}>
												Out Days
											</Text>
										</Flex>
										<Flex
											justifyContent={'start'}
											width={'100%'}
											gap={'8px'}>
											<Controller
												name="sick"
												control={control}
												render={({
													field: {
														ref,
														onBlur,
														onChange,
														value,
													},
												}) => (
													<CustomCheckbox
														ref={ref}
														isChecked={value}
														onBlur={onBlur}
														onChange={e =>
															onChange(
																e.target
																	.checked,
															)
														}
													/>
												)}
											/>
											<Text
												color={'dark'}
												width={'100%'}
												fontSize={'13px'}>
												Sick Days
											</Text>
										</Flex>
										<InputText
											label={'More Info'}
											variant={'flushed'}
											placeholder={'Enter More Info'}
											errorMsg={
												errors.moreInfo
													?.message as string
											}
											{...register(
												'moreInfo',
											)}></InputText>
									</>
								) : null}
							</VStack>
						</Flex>
					</form>

					<Flex display={'flex'} gap={'32px'} p={'0'}>
						<Button variant={'secondary'} onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant={'primary'}
							type={'submit'}
							isDisabled={isDisabled}>
							Save
						</Button>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
