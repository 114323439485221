import React, { PropsWithChildren, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Text,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Box,
	Button,
	Flex,
} from '@chakra-ui/react';
import { Icon } from '../../components';
import { AnimalInfo, FeedRation, PreSurvey, Weights } from './components';
export const Test: React.FC<PropsWithChildren> = () => {
	const navigate = useNavigate();
	const [tabIndex, setTabIndex] = useState(0);
	const tabListRef = useRef<HTMLDivElement | null>(null);

	const isLeftDisabled = tabIndex === 0;
	const isRightDisabled = tabIndex === 9;

	const handleBack = () => navigate(-1);

	const handleLeftClick = () => {
		if (tabIndex > 0) {
			setTabIndex(prev => prev - 1);
			scrollToTab(tabIndex);
		}
	};

	const handleRightClick = () => {
		if (tabIndex < 9) {
			setTabIndex(prev => prev + 1);
			scrollToTab(tabIndex);
		}
	};
	const scrollToTab = (index: number) => {
		const tabList = tabListRef.current;
		if (tabList) {
			const activeTab = tabList.children[index] as HTMLElement;
			if (activeTab) {
				activeTab.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
					inline: 'center',
				});
			}
		}
	};
	return (
		<Box>
			<Tabs variant="custom" index={tabIndex}>
				<Flex alignItems={'center'} gap={'16px'}>
					<Button
						onClick={handleBack}
						minWidth={'80px'}
						color={'primary'}
						p={'12px'}
						paddingLeft={'0px'}
						gap={'8px'}
						marginRight={'16px'}>
						<Icon
							name={'left-arrow'}
							width={'24px'}
							height={'24px'}
						/>
						Back
					</Button>
					<Text minWidth={'177px'} variant={'B03'} color={'dark'}>
						Test #8
					</Text>
					<Flex alignItems={'center'} gap={'16px'} minWidth={'636px'}>
						<Button
							px={0}
							onClick={handleLeftClick}
							isDisabled={isLeftDisabled}
							variant={'primary'}
							width={'24px'}
							height={'24px'}
							sx={{
								_disabled: {
									bg: 'gray',
									cursor: 'not-allowed',
									_hover: {
										bg: 'gray',
										cursor: 'not-allowed',
									},
								},
							}}
							borderRadius={'50%'}>
							<Icon
								color="white"
								name={'left-arrow'}
								width={'24px'}
								height={'24px'}
							/>
						</Button>
						<TabList
							overflow={'hidden'}
							maxWidth={'496px'}
							ref={tabListRef}>
							<Tab
								sx={{
									borderLeft: 'none',
								}}>
								Animal information
							</Tab>
							<Tab>User pre-survey</Tab>
							<Tab>Test Feed Ration</Tab>
							<Tab>Weights</Tab>
							<Tab>Test ‘Disqualified’ Days (Group)</Tab>
							<Tab>Test Notes</Tab>
							<Tab>Daily Feed Delivery</Tab>
							<Tab>Individual Animal Retags</Tab>
							<Tab>Individual Animal Sick Days</Tab>
							<Tab
								sx={{
									borderRight: 'none',
								}}>
								Animals that need to remove
							</Tab>
						</TabList>
						<Button
							variant={'primary'}
							onClick={handleRightClick}
							isDisabled={isRightDisabled}
							sx={{
								_disabled: {
									bg: 'gray',
									cursor: 'not-allowed',
									_hover: {
										bg: 'gray',
										cursor: 'not-allowed',
									},
								},
							}}
							width={'24px'}
							height={'24px'}
							borderRadius={'50%'}
							px={0}>
							<Icon
								name={'right-arrow'}
								color="white"
								width={'24px'}
								height={'24px'}
							/>
						</Button>
					</Flex>
					<Button
						variant={'primary'}
						height={'48px'}
						maxWidth={'195px'}
						gap={'8px'}
						fontWeight={'700'}>
						{' '}
						Refresh
						<Icon
							color={'#FFFFF'}
							name={'refresh'}
							width={'16px'}
							height={'16px'}
						/>
					</Button>
				</Flex>
				<TabPanels marginTop={'32px'}>
					<TabPanel>
						{' '}
						<AnimalInfo />
					</TabPanel>
					<TabPanel>
						<PreSurvey />
					</TabPanel>
					<TabPanel>
						<FeedRation />
					</TabPanel>
					<TabPanel>
						<Text>
							<Weights />
						</Text>
					</TabPanel>
					<TabPanel>
						<Text>Test ‘Disqualified’ Days (Group) content</Text>
					</TabPanel>
					<TabPanel>
						<Text>Test Notes content</Text>
					</TabPanel>
					<TabPanel>
						<Text>Daily Feed Delivery content</Text>
					</TabPanel>
					<TabPanel>
						<Text>Individual Animal Retags content</Text>
					</TabPanel>
					<TabPanel>
						<Text>Individual Animal Sick Days content</Text>
					</TabPanel>
					<TabPanel>
						<Text>Animals that need to remove content</Text>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};
