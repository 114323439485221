import React, { PropsWithChildren, useState } from 'react';
import {
	Button,
	Flex,
	Input,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import { Icon } from '../../../components';
import { AddStartWeightModal } from '../../../modals';
import { colors } from '../../../theme/colors';

const data = [
	{
		tag: '361L',
		eid: '702',
	},
	{
		tag: '361L',
		eid: '702',
	},
	{
		tag: '361L',
		eid: '702',
	},
	{
		tag: '361L',
		eid: '702',
	},
	{
		tag: '361L',
		eid: '702',
	},
	{
		tag: '361L',
		eid: '702',
	},
	{
		tag: '361L',
		eid: '702',
	},
	{
		tag: '361L',
		eid: '702',
	},
	{
		tag: '361L',
		eid: '702',
	},
	{
		tag: '361L',
		eid: '702',
	},
	{
		tag: '361L',
		eid: '702',
	},
];
const weight = [
	{
		id: 1,
		testWeight: '',
		wtDate: '8/9/2024',
		notes: '',
	},
	{
		id: 2,
		testWeight: '',
		wtDate: '8/9/2024',
		notes: '',
	},
	{
		id: 3,
		testWeight: '',
		wtDate: '8/9/2024',
		notes: '',
	},
	{
		id: 4,
		testWeight: '',
		wtDate: '8/9/2024',
		notes: '',
	},
	{
		id: 5,
		testWeight: '',
		wtDate: '8/9/2024',
		notes: '',
	},
	{
		id: 6,
		testWeight: '',
		wtDate: '8/9/2024',
		notes: '',
	},
	{
		id: 7,
		testWeight: '',
		wtDate: '8/9/2024',
		notes: '',
	},
	{
		id: 8,
		testWeight: '',
		wtDate: '8/9/2024',
		notes: '',
	},
	{
		id: 9,
		testWeight: '',
		wtDate: '8/9/2024',
		notes: '',
	},
	{
		id: 10,
		testWeight: '',
		wtDate: '8/9/2024',
		notes: '',
	},
];
export const Weights: React.FC<PropsWithChildren> = () => {
	const [tableWeights, setWeightsTable] = useState(weight);
	const [editingId, setEditingId] = useState<number | null>(null);
	const [editedTestWeight, setEditedTestWeight] = useState<string>(''); // Додано
	const [editedNotes, setEditedNotes] = useState<string>(''); // Додано
	const {
		isOpen: isAddAnimalModalOpen,
		onOpen: onAddAnimalModalOpen,
		onClose: onAddAnimalModalClose,
	} = useDisclosure();

	const handleAddWeight = (wtDate: string) => {
		console.log('123', wtDate);
	};

	const handleEditClick = (id: number) => {
		const weightItem = tableWeights.find(item => item.id === id);
		if (weightItem) {
			setEditingId(id);
			setEditedTestWeight(weightItem.testWeight);
			setEditedNotes(weightItem.notes);
		}
	};

	const handleSave = (id: number) => {
		const updatedWeights = tableWeights.map(item =>
			item.id === id
				? { ...item, testWeight: editedTestWeight, notes: editedNotes }
				: item,
		);
		setWeightsTable(updatedWeights);
		setEditingId(null);
	};

	return (
		<>
			<TableContainer
				backgroundColor={'light'}
				boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
				borderRadius={'12px'}
				padding={'16px'}
				width={'100%'}
				maxHeight={'900px'}
				overflowY={'auto'}
				position={'relative'}
				color="dark">
				<Flex gap={'24px'}>
					<Table variant="custom" width={'188px'} mt={'74px'}>
						<Thead backgroundColor={'white'}>
							<Tr>
								<Th p={'26px'}>Tag #</Th>
								<Th p={'26px'}>EID</Th>
							</Tr>
						</Thead>
						<Tbody backgroundColor={'white'}>
							{data.map((item, index) => (
								<Tr key={index}>
									<Td>{item.tag}</Td>
									<Td>{item.eid}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>

					<Table variant="custom" width={'461px'}>
						<Thead backgroundColor={'white'}>
							<Tr>
								<Th />
								<Th> Start Weight</Th>
								<Th />
								<Th>
									<Icon
										name={'trash'}
										width="20px"
										height="20px"
										color={colors.error}
									/>
								</Th>
							</Tr>
							<Tr>
								<Th p={'26px'}>Test Weight 1</Th>
								<Th p={'26px'}>Wt Date 1</Th>
								<Th p={'26px'}>Notes 1</Th>
								<Th p={'26px'}>Actions</Th>
							</Tr>
						</Thead>
						<Tbody backgroundColor={'white'}>
							{tableWeights.map(item => (
								<Tr key={item.id}>
									<Td>
										{editingId === item.id ? (
											<Input
												border="none"
												width="82px"
												_focus={{ boxShadow: 'none' }}
												value={editedTestWeight}
												onChange={e =>
													setEditedTestWeight(
														e.target.value,
													)
												}
											/>
										) : (
											item.testWeight
										)}
									</Td>
									<Td>{item.wtDate}</Td>
									<Td>
										{editingId === item.id ? (
											<Input
												border="none"
												width="82px"
												_focus={{ boxShadow: 'none' }}
												value={editedNotes}
												onChange={e =>
													setEditedNotes(
														e.target.value,
													)
												}
											/>
										) : (
											item.notes
										)}
									</Td>
									<Td>
										{editingId === item.id ? (
											<Button
												onClick={() =>
													handleSave(item.id)
												}>
												<Icon
													name="save"
													width={'18px'}
													height={'18px'}
												/>
											</Button>
										) : (
											<Button
												padding={0}
												onClick={() =>
													handleEditClick(item.id)
												}>
												<Icon
													name="small-pen"
													width={'20px'}
													height={'20px'}
													color={`${colors.dark}`}
												/>
											</Button>
										)}
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Flex>
			</TableContainer>
			<Button
				variant={'primary'}
				position={'absolute'}
				bottom={'55px'}
				right={'65px'}
				width={'195px'}
				gap={'8px'}
				onClick={onAddAnimalModalOpen}>
				<Icon name="add" width={'20px'} height={'20px'} color="light" />
				Add Weight #
			</Button>
			<AddStartWeightModal
				onClose={onAddAnimalModalClose}
				isOpen={isAddAnimalModalOpen}
				onAddWeight={handleAddWeight}
			/>
		</>
	);
};
