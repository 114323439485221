import React from 'react';
import {
	Box,
	Button,
	Flex,
	Image,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Text,
} from '@chakra-ui/react';
import { Icon } from '../../components';

interface ChangeAvatarModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const ChangeAvatarModal: React.FC<ChangeAvatarModalProps> = ({
	isOpen,
	onClose,
}) => {
	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent minH={'781px'} minW={'515px'} borderRadius={'24px'}>
				<ModalHeader
					display={'flex'}
					justifyContent={'space-between'}
					p={'32px 32px 24px'}
					borderBottom={'1px solid #ECECEC'}>
					<Text variant={'B03'}>Password change:</Text>
					<Box onClick={onClose} cursor={'pointer'}>
						<Icon name={'close'} width="24px" height="24px" />
					</Box>
				</ModalHeader>
				<Flex flexDirection={'column'} gap={'32px'} padding={'32px'}>
					<Button variant={'secondary'}>
						Upload photo
						<Box ml={'8px'}>
							<Icon name={'upload'} width="24px" height="24px" />
						</Box>
					</Button>
					<Flex
						backgroundColor={'primary'}
						h={'336px'}
						borderRadius={'6px'}
						alignItems={'center'}
						justifyContent={'center'}>
						<Image
							w={'268px'}
							h={'290px'}
							borderRadius={'6px'}
							src="https://bit.ly/code-beast"
						/>
					</Flex>
					<Flex gap={'13px'} paddingX={'7px'} alignItems={'center'}>
						<Icon
							name={'picture'}
							width="32px"
							height="32px"
							strokeColor={'none'}
						/>
						<Slider defaultValue={30}>
							<SliderTrack
								bg="gray"
								height="3px"
								borderRadius="5px">
								<SliderFilledTrack bg="primary" />
							</SliderTrack>
							<SliderThumb
								boxSize={6}
								bg="primary"
								borderRadius="50%"
							/>
						</Slider>
						<Icon
							name={'picture'}
							width="40px"
							height="40px"
							strokeColor={'none'}
						/>
					</Flex>
					<Flex gap={'64px'} justifyContent={'center'}>
						<Box cursor={'pointer'}>
							<Icon
								name={'left-refresh'}
								width="40px"
								height="40px"
							/>
						</Box>
						<Box cursor={'pointer'}>
							<Icon
								name={'right-refresh'}
								width="40px"
								height="40px"
							/>
						</Box>
					</Flex>

					<Flex display={'flex'} gap={'32px'} p={'0'}>
						<Button variant={'secondary'} onClick={onClose}>
							Cancel
						</Button>
						<Button variant={'primary'} onClick={onClose}>
							Save
						</Button>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
