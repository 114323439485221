import React, { PropsWithChildren } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { Icon } from '../../Icon/Icon';
import { colors } from '../../../theme/colors';

interface IPasswordRuleProps extends PropsWithChildren {
	isValid?: boolean;
}

export const PasswordRule: React.FC<IPasswordRuleProps> = ({
	children,
	isValid,
}) => {
	return (
		<HStack spacing="8px">
			<Icon
				name="check-circle"
				width="24px"
				height="24px"
				color={isValid ? '#8ADAAA' : colors.error}
			/>
			<Text color="gray.100" variant="B02">
				{children}
			</Text>
		</HStack>
	);
};
