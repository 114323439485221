import React, { useState } from 'react';
import {
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { CustomSelect } from '../../components';

interface AddAnimalInfoModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AddAnimalInfoModal: React.FC<AddAnimalInfoModalProps> = ({
	isOpen,
	onClose,
}) => {
	const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				minH={isSelectOpen ? '446px' : '288px'}
				minW={'538px'}
				justifyContent={'space-between'}
				alignItems={'center'}
				flexDirection={'column'}
				borderRadius={'24px'}>
				<Flex alignItems={'center'} flexDirection={'column'}>
					<ModalHeader
						display={'flex'}
						justifyContent={'center'}
						p={'32px 32px 24px'}>
						<Text variant={'B03'} color={'dark'}>
							Add Animal
						</Text>
					</ModalHeader>
					<Flex minW={'422px'}>
						<CustomSelect
							handleOpen={() => setIsSelectOpen(prev => !prev)}
							maxH={'200px'}
							label="Ranch Tag"
							placeholder="Select Ranch Tag"
							options={[
								{ label: 'G230', value: 'g230' },
								{ label: 'G231', value: 'g231' },
								{ label: 'G232', value: 'g232' },
								{ label: 'G233', value: 'g233' },
								{ label: 'G234', value: 'g234' },
								{ label: 'G235', value: 'g235' },
								{ label: 'G236', value: 'g236' },
								{ label: 'G237', value: 'g237' },
								{ label: 'G238', value: 'g238' },
								{ label: 'G239', value: 'g239' },
							]}
						/>
					</Flex>
				</Flex>
				<Flex gap={'24px'} p={'32px'} minW={'422px'}>
					<Button variant={'secondary'} onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant={'primary'}
						isDisabled={true}
						onClick={onClose}>
						Add
					</Button>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
