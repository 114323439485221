import React from 'react';
import {
	Box,
	Button,
	Flex,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { Icon } from '../../components';
import { shadows } from '../../theme/shadows';

interface DeleteAnimalPopoverProps {
	onDelete?: () => void;
	color?: string;
}

export const DeleteAnimalPopover: React.FC<DeleteAnimalPopoverProps> = ({
	color,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<Popover isOpen={isOpen} onOpen={onOpen}>
			<PopoverTrigger>
				<Button padding={0}>
					<Icon
						name="trash"
						width={'20px'}
						height={'20px'}
						color={color}
					/>
				</Button>
			</PopoverTrigger>
			<PopoverContent
				minH={'288px'}
				maxW={'296px'}
				borderRadius={'24px'}
				border={'1px solid #175D00'}
				boxShadow={shadows.dark}>
				<PopoverHeader
					display={'flex'}
					whiteSpace={'normal'}
					textAlign={'center'}
					justifyContent={'space-between'}
					p={'32px 32px 24px'}
					borderBottom={'1px solid #ECECEC'}>
					<Text variant={'B03'}>
						Are you sure you want to delete information for this
						animal?
					</Text>
				</PopoverHeader>
				<PopoverBody>
					<Flex flexDirection={'column'} gap={'24px'} p={'32px'}>
						<Button variant={'secondary'} onClick={onClose}>
							Cancel
						</Button>
						<Button variant={'delete'} onClick={onClose}>
							Delete
							<Box ml={'8px'}>
								<Icon
									name={'trash'}
									width="24px"
									height="24px"
									color={'secondary'}
								/>
							</Box>
						</Button>
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
