import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { ROUTE_PATH } from '../../../constants/route-paths';
import { Link } from 'react-router-dom';
import { InputPassword, InputText } from '../../../components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SignInFormValues } from '../types';

export const SignIn = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm<SignInFormValues>();

	const onSubmit: SubmitHandler<SignInFormValues> = data => {
		console.log(data);
	};
	const watchFields = watch(['email', 'password']);
	const isDisabled = !watchFields.every(field => field);

	return (
		<Flex
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'center'}
			gap={'40px'}
			width={'422px'}>
			<Flex flexDirection={'column'} alignItems={'center'} gap={'16px'}>
				<Text color={'dark'} variant={'B04'}>
					Welcome back
				</Text>
				<Text color={'dark'} variant={'B02'}>
					Log In to your account
				</Text>
			</Flex>
			<VStack spacing={'24px'} width={'100%'}>
				<InputText
					{...register('email', {
						required: 'Email is required',
						pattern: {
							value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
							message: 'Invalid email address',
						},
					})}
					errorMsg={errors.email?.message as string}
					label={'Your email'}
					variant={'flushed'}
					placeholder={'Enter your email'}
					autoComplete={'email'}
				/>
				<InputPassword
					errorMsg={errors.password?.message as string}
					{...register('password', {
						required: 'Password is required',
					})}
					label={'Your password'}
					variant={'flushed'}
					placeholder={'Enter your password'}
					showRules={false}></InputPassword>
			</VStack>
			<Flex justifyContent={'end'} width={'100%'}>
				<Link to={ROUTE_PATH.forgot_password}>
					<Text variant={'B01'}>Forgot password?</Text>
				</Link>
			</Flex>
			<Button
				variant={'primary'}
				isDisabled={isDisabled}
				onClick={handleSubmit(onSubmit)}>
				Log In
			</Button>
			<Flex gap={'8px'}>
				<Text color={'dark'}>Don’t have an account?</Text>
				<Link to={ROUTE_PATH.sign_up}>
					<Text fontWeight={'700'}>Sign Up</Text>
				</Link>
			</Flex>
		</Flex>
	);
};
