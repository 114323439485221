import {
	Button,
	Flex,
	Input,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { useState } from 'react';
import { DeleteAnimalPopover } from '../../../modals';
import { colors } from '../../../theme/colors';
import { Icon } from '../../../components';
import { inputStyles } from '../../../constants/input-styles';

interface FeedInfo {
	asFed: string;
	dm: string;
	ingredient: string;
	feedNotes: string;
	isEdit: boolean;
}

const initialData: FeedInfo[] = [];

export const FeedRation = () => {
	const [data, setData] = useState(initialData);

	const handleAddRow = () => {
		setData([
			...data,
			{ asFed: '', dm: '', ingredient: '', feedNotes: '', isEdit: false },
		]);
	};
	const handleChange = (
		index: number,
		field: keyof FeedInfo,
		value: string,
	) => {
		const updatedData = [...data];
		updatedData[index] = { ...updatedData[index], [field]: value };
		setData(updatedData);
	};

	const handleEditRow = (index: number) => {
		const updatedData = data.map((row, i) =>
			i === index ? { ...row, isEdit: !row.isEdit } : row,
		);
		setData(updatedData);
	};

	return (
		<Flex direction="column" p={6}>
			<Table
				maxW={'993px'}
				variant="simple"
				mb={4}
				sx={{
					td: { borderBottom: 'none' },
					th: { borderBottom: 'none' },
				}}>
				<Thead>
					<Tr>
						<Th>As fed %</Th>
						<Th>DM %</Th>
						<Th>Ingredient</Th>
						<Th>Feed Notes</Th>
						<Th>Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((row, index) => (
						<Tr key={index}>
							<Td minW={'180px'}>
								{row.isEdit ? (
									<Input
										{...inputStyles}
										maxW={'170px'}
										value={row.asFed}
										onChange={e =>
											handleChange(
												index,
												'asFed',
												e.target.value,
											)
										}
									/>
								) : (
									row.asFed
								)}
							</Td>
							<Td minW={'180px'}>
								{row.isEdit ? (
									<Input
										{...inputStyles}
										maxW={'170px'}
										value={row.dm}
										onChange={e =>
											handleChange(
												index,
												'dm',
												e.target.value,
											)
										}
									/>
								) : (
									row.dm
								)}
							</Td>
							<Td minW={'180px'}>
								{row.isEdit ? (
									<Input
										{...inputStyles}
										maxW={'170px'}
										value={row.ingredient}
										onChange={e =>
											handleChange(
												index,
												'ingredient',
												e.target.value,
											)
										}
									/>
								) : (
									row.ingredient
								)}
							</Td>
							<Td minW={'180px'}>
								{row.isEdit ? (
									<Input
										{...inputStyles}
										maxW={'170px'}
										value={row.feedNotes}
										onChange={e =>
											handleChange(
												index,
												'feedNotes',
												e.target.value,
											)
										}
									/>
								) : (
									row.feedNotes
								)}
							</Td>
							<Td minW={'180px'}>
								<Button
									padding={0}
									onClick={() => handleEditRow(index)}>
									{row.isEdit ? (
										<Icon
											name="save"
											width={'20px'}
											height={'20px'}
											color={`${colors.dark}`}
										/>
									) : (
										<Icon
											name="small-pen"
											width={'20px'}
											height={'20px'}
											color={`${colors.dark}`}
										/>
									)}
								</Button>
								<DeleteAnimalPopover
									color={`${colors.error}`}
								/>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
			<Flex justify="center" mb={4}>
				<Button onClick={handleAddRow} color={'primary'}>
					<Icon
						name="add"
						width={'20px'}
						height={'20px'}
						color={`${colors.primary}`}
					/>
					Add Info
				</Button>
			</Flex>
			<Flex gap={'10px'} align="center" pt={4}>
				<Text color={'dark'} fontWeight="bold">
					Total
				</Text>
				<Text color={'dark'}>1.00</Text>
				<Text color={'dark'}>1.00</Text>
			</Flex>

			<Flex gap={'10px'} align="center" pt={4}>
				<Text color={'dark'} fontWeight="bold">
					Feed Notes
				</Text>
				<Text color={'dark'}>48 Neg</Text>
			</Flex>
		</Flex>
	);
};
