import React, { PropsWithChildren } from 'react';
import {
	Box,
	Button,
	Container,
	Flex,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InputPassword, InputText, Icon } from '../../../components';
import { ProfileSettingsFormValues } from '../types';
import { ChangePasswordModal, LogOutModal } from '../../../modals';

export const ProfileSettingsForm: React.FC<PropsWithChildren> = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm<ProfileSettingsFormValues>();
	const onSubmit: SubmitHandler<ProfileSettingsFormValues> = data => {
		console.log(data);
	};

	const watchFields = watch(['username', 'phone', 'email', 'password']);
	const isDisabled = !watchFields.every(field => field);

	const {
		isOpen: isPasswordModalOpen,
		onOpen: onPasswordModalOpen,
		onClose: onPasswordModalClose,
	} = useDisclosure();
	const {
		isOpen: isLogOutModalOpen,
		onOpen: onLogOutModalOpen,
		onClose: onLogOutModalClose,
	} = useDisclosure();

	return (
		<Container w={'100%'} h={'100%'} padding={'0'}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Flex flexDirection={'column'} gap={'40px'}>
					<VStack spacing={'24px'}>
						<InputText
							label={'Your username'}
							variant={'flushed'}
							placeholder={'Enter your username'}
							autoComplete={''}
							rightElement={
								<Icon
									name={'small-pen'}
									width="16px"
									height="16px"
									color={'gray'}
								/>
							}
							{...register('username', {
								required: 'Username is required',
							})}
							errorMsg={errors.username?.message as string}
						/>
						<InputText
							label={'Your phone number'}
							variant={'flushed'}
							placeholder={'Enter your phone number'}
							autoComplete={''}
							rightElement={
								<Icon
									name={'small-pen'}
									width="16px"
									height="16px"
									color={'gray'}
								/>
							}
							{...register('phone', {
								required: 'Enter your phone',
								pattern: {
									value: /^[0-9]+$/,
									message: 'Phone number must be digits only',
								},
							})}
							errorMsg={errors.phone?.message as string}
						/>
						<InputText
							label={'Your email'}
							variant={'flushed'}
							placeholder={'Enter your email'}
							autoComplete={'email'}
							rightElement={
								<Icon
									name={'small-pen'}
									width="16px"
									height="16px"
									color={'gray'}
								/>
							}
							{...register('email', {
								required: 'Email is required',
								pattern: {
									value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
									message: 'Invalid email address',
								},
							})}
							errorMsg={errors.email?.message as string}
						/>
						<Box onClick={onPasswordModalOpen} w={'100%'}>
							<InputPassword
								label={'Your password'}
								variant={'flushed'}
								placeholder={'Enter your password'}
								showRules={false}
								errorMsg={errors.password?.message as string}
								rightElement={
									<Icon
										name={'right-arrow'}
										width="16px"
										height="16px"
										color={'gray'}
									/>
								}
								{...register('password', {
									required: 'Password is required',
								})}></InputPassword>
						</Box>
					</VStack>
					<Button
						variant={'primary'}
						isDisabled={!isDisabled}
						onClick={handleSubmit(onSubmit)}>
						Save Changes
					</Button>
					<Button variant={'secondary'} onClick={onLogOutModalOpen}>
						Log Out
						<Box ml={'8px'}>
							<Icon name={'log-out'} width="24px" height="24px" />
						</Box>
					</Button>
				</Flex>
			</form>
			<ChangePasswordModal
				isOpen={isPasswordModalOpen}
				onClose={onPasswordModalClose}
			/>
			<LogOutModal
				isOpen={isLogOutModalOpen}
				onClose={onLogOutModalClose}
			/>
		</Container>
	);
};
