import React, { PropsWithChildren } from 'react';
import {
	Button,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import { Icon } from '../../components';
import { colors } from '../../theme/colors';
import {
	AddAnimalModal,
	DeleteAnimalPopover,
	EditAnimalModal,
} from '../../modals';

const data = [
	{
		ranchTag: 'G230',
		regNumber: '3664479',
		birthDate: '8/18/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'KBHR HIGH ROAD E283',
		dam: 'IR MS GENEVA E791',
		eidFull: '94A',
		eidTruncated: 'C4B',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G230',
		regNumber: '3664479',
		birthDate: '8/18/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'KBHR HIGH ROAD E283',
		dam: 'IR MS GENEVA E791',
		eidFull: '94A',
		eidTruncated: 'C4B',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G230',
		regNumber: '3664479',
		birthDate: '8/18/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'KBHR HIGH ROAD E283',
		dam: 'IR MS GENEVA E791',
		eidFull: '94A',
		eidTruncated: 'C4B',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
];

export const Animals: React.FC<PropsWithChildren> = () => {
	const {
		isOpen: isAddAnimalModalOpen,
		onOpen: onAddAnimalModalOpen,
		onClose: onAddAnimalModalClose,
	} = useDisclosure();
	const {
		isOpen: isEditAnimalModalOpen,
		onOpen: onEditAnimalModalOpen,
		onClose: onEditAnimalModalClose,
	} = useDisclosure();
	return (
		<>
			<TableContainer
				backgroundColor={'light'}
				boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
				borderRadius={'12px'}
				padding={'16px'}
				width={'100%'}
				maxHeight={'900px'}
				overflowY={'auto'}
				position={'relative'}>
				<Table variant="custom">
					<Thead>
						<Tr>
							<Th>Ranch Tag</Th>
							<Th>Reg #</Th>
							<Th>BD</Th>
							<Th>Sex</Th>
							<Th>DNA collected?</Th>
							<Th>Sire</Th>
							<Th>Dam</Th>
							<Th>EID full</Th>
							<Th>EID truncated</Th>
							<Th>EID Notes</Th>
							<Th>Contemporary Group</Th>
							<Th>Out or Sick Days</Th>
							<Th>Actions</Th>
						</Tr>
					</Thead>
					<Tbody>
						{data.map((item, index) => (
							<Tr key={index}>
								<Td>{item.ranchTag}</Td>
								<Td>{item.regNumber}</Td>
								<Td>{item.birthDate}</Td>
								<Td>{item.sex}</Td>
								<Td>{item.dnaCollected}</Td>
								<Td>{item.sire}</Td>
								<Td>{item.dam}</Td>
								<Td>{item.eidFull}</Td>
								<Td>{item.eidTruncated}</Td>
								<Td>{item.eidNotes}</Td>
								<Td>{item.contemporaryGroup}</Td>
								<Td>{item.outOrSickDays}</Td>
								<Td>
									<Button
										padding={0}
										onClick={onEditAnimalModalOpen}>
										<Icon
											name="small-pen"
											width={'20px'}
											height={'20px'}
											color={`${colors.dark}`}
										/>
									</Button>
									<DeleteAnimalPopover
										color={`${colors.error}`}
									/>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
			<Button
				variant={'primary'}
				position={'absolute'}
				bottom={'55px'}
				right={'65px'}
				width={'165px'}
				gap={'8px'}
				onClick={onAddAnimalModalOpen}>
				{' '}
				<Icon name="add" width={'20px'} height={'20px'} color="light" />
				Add Animal
			</Button>
			<AddAnimalModal
				onClose={onAddAnimalModalClose}
				isOpen={isAddAnimalModalOpen}
			/>
			<EditAnimalModal
				onClose={onEditAnimalModalClose}
				isOpen={isEditAnimalModalOpen}
			/>
		</>
	);
};
