import React from 'react';
import {
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	Text,
} from '@chakra-ui/react';
import { Icon } from '../../components';
import { shadows } from '../../theme/shadows';

interface VerifyEmailModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const VerifyEmailModal: React.FC<VerifyEmailModalProps> = ({
	isOpen,
	onClose,
}) => {
	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalContent
				minH={'344px'}
				maxW={'477px'}
				borderRadius={'24px'}
				border={'1px solid #175D00'}
				boxShadow={shadows.dark}>
				<ModalHeader
					display={'flex'}
					justifyContent={'space-between'}
					flexDirection={'column'}
					p={'32px 32px 24px'}
					gap={'16px'}
					borderBottom={'1px solid #ECECEC'}>
					<Flex alignItems={'center'} gap={'16px'}>
						<Icon
							name={'check'}
							width="32px"
							height="32px"
							color={'#175D00'}
						/>
						<Text color={'dark'} variant={'B04'}>
							Verify your email
						</Text>
					</Flex>
					<Text color={'dark'} variant={'B02'} textAlign={'center'}>
						We have sent you a verification code to complete your
						email registration. Please check your email and enter
						the verification code.
					</Text>
				</ModalHeader>
				<Flex flexDirection={'column'} gap={'24px'} p={'32px'}>
					<Button variant={'primary'} onClick={onClose}>
						Ok
					</Button>
					<Text color={'error'} textAlign={'center'}>
						{' '}
						** Please check your spam/junk folder, if you did not
						receive the email with the verification code.
					</Text>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
