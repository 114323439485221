import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { inputStyles } from '../../../constants/input-styles';
import { CustomDatePicker } from '../../../components';

export const PreSurvey = () => {
	return (
		<form>
			<Flex gap={'40px'}>
				<Flex width={'532px'} flexDirection={'column'}>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Customer</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Representative</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Customer ID</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Premise ID</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Premise State</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Test #</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Test Group</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Test Head #</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Breed</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Weight Range (Start)</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>V-Gate setting</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>
							Turn-in Date (Warm-up)
						</FormLabel>
						<CustomDatePicker />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Test Start</FormLabel>
						<CustomDatePicker />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>
							Test Finish (projected)
						</FormLabel>
						<CustomDatePicker />
					</FormControl>
				</Flex>
				<Flex width={'532px'} flexDirection={'column'}>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>
							Test Diet cost/lb (AS FED)
						</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Test Dry Matter</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>NEg</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>NEm</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Cleanout EID Tag #1</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
					<FormControl flexDirection={'row'} display={'flex'}>
						<FormLabel w={'343px'}>Cleanout EID Tag #2</FormLabel>
						<Input {...inputStyles} />
					</FormControl>
				</Flex>
			</Flex>
		</form>
	);
};
