import React, { PropsWithChildren } from 'react';
import { Avatar, Flex, Image, Text } from '@chakra-ui/react';
import { Icon } from '../components';
import { ROUTE_PATH } from '../constants/route-paths';
import { NavLinkItem } from './components/NavLinkItem';
import { Outlet, Link } from 'react-router-dom';
import backgroundTop from '../../public/images/background-top.png';
import backgroundBottom from '../../public/images/background-bottom.png';

const NAV_ITEMS = [
	{
		name: <Text color={'inherit'}>Animals</Text>,
		icon: (
			<Icon
				name={'animals'}
				width={'24px'}
				height={'24px'}
				color={'currentColor'}
			/>
		),
		path: ROUTE_PATH.animals,
	},
	{
		name: <Text color={'inherit'}>Tests</Text>,
		icon: (
			<Icon
				name={'tests'}
				width={'24px'}
				height={'24px'}
				color={'currentColor'}
			/>
		),
		path: ROUTE_PATH.tests,
	},
	// {
	// 	name: <Text color={'inherit'}>Final Output</Text>,
	// 	icon: (
	// 		<Icon
	// 			name={'round-dashboard'}
	// 			width={'24px'}
	// 			height={'24px'}
	// 			color={'currentColor'}
	// 		/>
	// 	),
	// 	path: ROUTE_PATH.final_output,
	// },
	{
		name: <Text color={'inherit'}>Final Report</Text>,
		icon: (
			<Icon
				name={'round-dashboard'}
				width={'24px'}
				height={'24px'}
				color={'currentColor'}
			/>
		),
		path: ROUTE_PATH.final_report,
	},
	// {
	// 	name: <Text color={'inherit'}>Upload Files</Text>,
	// 	icon: (
	// 		<Icon
	// 			name={'upload-files'}
	// 			width={'24px'}
	// 			height={'24px'}
	// 			color={'currentColor'}
	// 		/>
	// 	),
	// 	path: ROUTE_PATH.upload_files,
	// },
];

export const MainLayout: React.FC<PropsWithChildren> = () => {
	return (
		<Flex sx={{ height: 'calc(100vh - 77px)', overflow: 'hidden' }}>
			<Flex
				w={'160px'}
				boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.25)'}
				flexDirection={'column'}
				justifyContent={'space-between'}>
				<Flex
					flexDirection={'column'}
					gap={'24px'}
					m={'40px 0'}
					w={'100%'}>
					{NAV_ITEMS.map((it, index) => (
						<NavLinkItem
							name={it.name}
							icon={it.icon}
							path={it.path}
							key={`item-${index}`}
						/>
					))}
				</Flex>
				<Flex
					backgroundColor={'primary'}
					p={'40px 16px 8px'}
					gap={'8px'}
					flexDirection={'column'}
					position={'relative'}>
					<Link to={ROUTE_PATH.profile_settings}>
						<Avatar
							w={'64px'}
							h={'64px'}
							border={'2px solid #F1FFEC'}
							pos={'absolute'}
							top={'-32px'}
							left={'50%'}
							transform={'translate(-50%, 0)'}
							cursor={'pointer'}
							name="Christian Nwamba"
							src="https://bit.ly/code-beast"
						/>
					</Link>
					<Text color={'secondary'}>incendiary_moldmaker_43</Text>
					<Text color={'secondary'}>(000) 000-0000</Text>
				</Flex>
			</Flex>

			<Flex
				flex={1}
				p={'60px 40px 60px 40px'}
				h={'100%'}
				overflowY={'auto'}
				position={'relative'}>
				<Image
					pos="absolute"
					top={'0'}
					left={'0'}
					src={backgroundTop}
					alt={''}
					w={'996px'}
					h={'671px'}
					zIndex={'-1'}
				/>
				<Image
					pos="fixed"
					bottom={'0'}
					right={'90'}
					src={backgroundBottom}
					alt={''}
					w={'970px'}
					h={'542px'}
					zIndex={'-1'}
				/>
				<Outlet />
			</Flex>
		</Flex>
	);
};
