import { ComponentStyleConfig } from '@chakra-ui/react';
import { shadows } from '../shadows';

export const Button: ComponentStyleConfig = {
	variants: {
		primary: {
			color: 'secondary',
			border: '1px solid transparent',
			backgroundColor: '#175D00',
			borderRadius: '8px',
			width: '100%',
			py: '16px',
			fontSize: '14px',
			height: 'auto',
			_hover: {
				boxShadow: shadows.light,
				fontWeight: '700',
			},
			_focusVisible: {
				boxShadow: shadows.light,
			},
			_disabled: {
				background: 'lightGray',
				color: 'gray',
				_hover: {
					background: 'lightGray !important',
				},
			},
		},
		delete: {
			color: 'secondary',
			border: '1px solid transparent',
			backgroundColor: '#AF0000',
			borderRadius: '8px',
			width: '100%',
			py: '16px',
			fontSize: '14px',
			height: 'auto',
			_hover: {
				boxShadow: shadows.light,
				fontWeight: '700',
			},
			_focusVisible: {
				boxShadow: shadows.light,
			},
			_disabled: {
				background: 'lightGray',
				color: 'gray',
				_hover: {
					background: 'lightGray !important',
				},
			},
		},
		secondary: {
			color: 'primary',
			border: '1px solid #175D00',
			borderRadius: '8px',
			width: '100%',
			py: '16px',
			fontSize: '14px',
			height: 'auto',
			_hover: {
				fontWeight: '700',
			},
			_focusVisible: {
				py: '15px',
				boxShadow: shadows.light,
				border: '2px solid #175D00',
			},
			_disabled: {
				background: 'lightGray',
				color: 'gray',
				_hover: {
					background: 'lightGray !important',
				},
			},
		},
	},
};
