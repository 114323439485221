import {
	Button,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { Icon } from '../../components';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../constants/route-paths';
const data = [
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
	{
		test: '8',
		testGroup: 'Fall 2019 Bulls - Group 1',
		customer: 'Irvine Ranch',
		customerId: '1001',
	},
];
export const FinalReport = () => {
	const navigate = useNavigate();
	const handleDetails = () => navigate(`${ROUTE_PATH.report}`);
	return (
		<TableContainer
			backgroundColor={'light'}
			boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
			borderRadius={'12px'}
			padding={'16px'}
			w={'100%'}
			maxHeight={'900px'}
			overflowY={'auto'}
			position={'relative'}>
			<Table variant="custom">
				<Thead>
					<Tr>
						<Th>Report #</Th>
						<Th>Test group</Th>
						<Th>Customer</Th>
						<Th>Customer ID</Th>
						<Th>Details</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, index) => (
						<Tr key={index}>
							<Td>{item.test}</Td>
							<Td>{item.testGroup}</Td>
							<Td>{item.customer}</Td>
							<Td>{item.customerId}</Td>
							<Td>
								<Button onClick={handleDetails}>
									{' '}
									<Icon
										name="right-arrow"
										color="dark"
										width={'20px'}
										height={'20px'}
									/>
								</Button>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
