import React, { PropsWithChildren } from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import logo from '../assets/images/logo.svg';
import { Icon, SearchBar } from '../components';

export const HeaderLayout: React.FC<PropsWithChildren> = () => {
	return (
		<Flex
			h={'77px'}
			w={'100%'}
			padding={'16px 40px'}
			boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.25)'}>
			<Flex gap={'96px'} alignItems={'center'} w={'100%'}>
				<Image src={logo} alt={''} w={'64px'} h={'45px'} />
				<SearchBar
					placeholder="Search for anything here..."
					height={'40px'}
					maxWidth={'700px'}
				/>
			</Flex>
			<Flex
				alignItems={'center'}
				justifyContent={'flex-end'}
				gap={'40px'}>
				<Box position={'relative'}>
					<Icon
						name={'notification'}
						width="24px"
						height="24px"
						color={'dark'}
						strokeColor={'none'}
					/>
					<Box position={'absolute'} top={'0'} right={'0'}>
						<Icon
							name={'new-notification'}
							width="6px"
							height="6px"
							color={'error'}
							strokeColor={'none'}
						/>
					</Box>
				</Box>
				<Flex gap={'8px'} alignItems={'center'}>
					<Icon
						name={'calendar'}
						width="16px"
						height="16px"
						color={'dark'}
					/>

					<Text color={'dark'} sx={{ textWrap: 'nowrap' }}>
						Wed, September 25th
					</Text>
				</Flex>
			</Flex>
		</Flex>
	);
};
