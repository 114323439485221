import React from 'react';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Icon, InputPassword } from '../../components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ChangePasswordFormValues } from './types';

interface ChangePasswordModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
	isOpen,
	onClose,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm<ChangePasswordFormValues>();
	const onSubmit: SubmitHandler<ChangePasswordFormValues> = data => {
		console.log(data);
	};
	const watchFields = watch(['password', 'newPassword', 'confirmPassword']);
	const isDisabled = !watchFields.every(field => field);

	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent minH={'472px'} minW={'486'} borderRadius={'24px'}>
				<ModalHeader
					display={'flex'}
					justifyContent={'space-between'}
					p={'32px 32px 24px'}
					borderBottom={'1px solid #ECECEC'}>
					<Text variant={'B03'}>Profile photo change:</Text>
					<Box onClick={onClose} cursor={'pointer'}>
						<Icon name={'close'} width="24px" height="24px" />
					</Box>
				</ModalHeader>
				<Flex flexDirection={'column'} gap={'40px'} padding={'32px'}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Flex flexDirection={'column'} gap={'40px'}>
							<VStack spacing={'24px'}>
								<InputPassword
									label={'Your password'}
									variant={'flushed'}
									placeholder={'Enter your password'}
									showRules={false}
									errorMsg={
										errors.password?.message as string
									}
									{...register('password', {
										required: 'Password is required',
									})}></InputPassword>{' '}
								<InputPassword
									label={'New password'}
									variant={'flushed'}
									placeholder={'Enter your new password'}
									showRules={false}
									errorMsg={
										errors.newPassword?.message as string
									}
									{...register('newPassword', {
										required: 'Password is required',
									})}></InputPassword>{' '}
								<InputPassword
									label={'Confirm new password'}
									variant={'flushed'}
									placeholder={'Repeat new password'}
									showRules={false}
									errorMsg={
										errors.confirmPassword
											?.message as string
									}
									{...register('confirmPassword', {
										required: 'Password is required',
									})}></InputPassword>
							</VStack>
						</Flex>
					</form>

					<Flex>
						<Button
							variant={'primary'}
							onClick={onClose}
							isDisabled={!isDisabled}>
							Change Password
						</Button>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
