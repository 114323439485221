import React, { PropsWithChildren } from 'react';
import {
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { colors } from '../../../theme/colors';
import { DeleteAnimalPopover } from '../../../modals';

const data = [
	{
		ranchTag: 'G230',
		regNumber: '3664479',
		birthDate: '8/18/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'KBHR HIGH ROAD E283',
		dam: 'IR MS GENEVA E791',
		eidFull: '94A',
		eidTruncated: 'C4B',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G230',
		regNumber: '3664479',
		birthDate: '8/18/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'KBHR HIGH ROAD E283',
		dam: 'IR MS GENEVA E791',
		eidFull: '94A',
		eidTruncated: 'C4B',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G230',
		regNumber: '3664479',
		birthDate: '8/18/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'KBHR HIGH ROAD E283',
		dam: 'IR MS GENEVA E791',
		eidFull: '94A',
		eidTruncated: 'C4B',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
	{
		ranchTag: 'G231',
		regNumber: '3664480',
		birthDate: '8/15/2019',
		sex: 'B',
		dnaCollected: 'yes',
		sire: 'HOOKS ADMIRAL 33A',
		dam: 'IR MS VIENNA B426',
		eidFull: 'C4B',
		eidTruncated: '94A',
		eidNotes: '',
		contemporaryGroup: 'Natural',
		outOrSickDays: 'leg injury',
	},
];

export const AnimalInfo: React.FC<PropsWithChildren> = () => {
	return (
		<TableContainer
			backgroundColor={'light'}
			boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
			borderRadius={'12px'}
			padding={'16px'}
			maxHeight={'900px'}
			overflowY={'auto'}
			position={'relative'}>
			<Table variant="custom">
				<Thead>
					<Tr>
						<Th>Ranch Tag</Th>
						<Th>Reg #</Th>
						<Th>BD</Th>
						<Th>Sex</Th>
						<Th>DNA collected?</Th>
						<Th>Sire</Th>
						<Th>Dam</Th>
						<Th>EID full</Th>
						<Th>EID truncated</Th>
						<Th>EID Notes</Th>
						<Th>Contemporary Group</Th>
						<Th>Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data.map((item, index) => (
						<Tr key={index}>
							<Td>{item.ranchTag}</Td>
							<Td>{item.regNumber}</Td>
							<Td>{item.birthDate}</Td>
							<Td>{item.sex}</Td>
							<Td>{item.dnaCollected}</Td>
							<Td>{item.sire}</Td>
							<Td>{item.dam}</Td>
							<Td>{item.eidFull}</Td>
							<Td>{item.eidTruncated}</Td>
							<Td>{item.eidNotes}</Td>
							<Td>{item.contemporaryGroup}</Td>
							<Td>
								<DeleteAnimalPopover
									color={`${colors.error}`}
								/>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
