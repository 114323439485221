import { ComponentStyleConfig } from '@chakra-ui/react';

export const Tabs: ComponentStyleConfig = {
	variants: {
		custom: {
			tab: {
				color: 'dark',
				border: '1px solid',
				borderColor: 'lightGray',
				borderTop: 'none',
				_selected: {
					color: 'green',
					borderBottomColor: 'green',
					borderBottom: '3px solid',
					borderRadius: '3px',
				},
				_hover: {
					color: 'green',
					borderBottomColor: 'green',
					borderBottom: '3px solid',
					borderRadius: '3px',
				},
			},
			tablist: {},
		},
	},
};
