import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.css';
import DatePicker from 'react-datepicker';
import { Icon } from '../Icon/Icon';
import { CustomHeader } from './components/CustomHeader';
import { CustomInput } from './components/CustomInput';
import { FormControl, FormControlProps, FormLabel } from '@chakra-ui/react';
import { labelStyles } from '../../constants';
import { UseFormSetValue } from 'react-hook-form';
interface CustomDatePickerProps {
	label?: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	showTime?: boolean;
	toggleCalender?: (open: boolean) => void;
	setValue?: UseFormSetValue<any>;
}
export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
	label,
	errorMsg,
	formControlProps,
	showTime = true,
	toggleCalender = () => {},
	setValue,
}) => {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [isYearPickerShow, setIsYearPickerShow] = useState<boolean>(false);

	const handleDateChange = (date: Date | null) => {
		setSelectedDate(date!);
		if (setValue) {
			setValue('wtDate', date);
		}
	};

	return (
		<FormControl isInvalid={!!errorMsg} {...formControlProps}>
			{label ? (
				<FormLabel {...labelStyles} mb={'24px'}>
					{label}
				</FormLabel>
			) : null}
			<DatePicker
				selected={selectedDate}
				onChange={handleDateChange}
				icon={<Icon name="calendar" width={'16px'} height={'16px'} />}
				showIcon={true}
				renderCustomHeader={props => (
					<CustomHeader
						setIsYearPickerShow={setIsYearPickerShow}
						{...props}
					/>
				)}
				popperPlacement={showTime ? 'right-start' : 'bottom-end'}
				portalId="date-picker"
				showYearPicker={isYearPickerShow}
				showTimeInput={showTime}
				customTimeInput={
					<CustomInput
						date={selectedDate}
						setSelectedDate={setSelectedDate}
					/>
				}
				timeIntervals={15}
				timeFormat="h:mm aa"
				toggleCalendarOnIconClick={true}
				closeOnScroll={true}
				dateFormat={showTime ? 'M/d/yyyy h:mm aa' : 'M/d/yyyy'}
				shouldCloseOnSelect={false}
				onCalendarOpen={() => toggleCalender(true)}
				onCalendarClose={() => toggleCalender(false)}
			/>
		</FormControl>
	);
};
