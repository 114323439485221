import React, { PropsWithChildren } from 'react';
import { Avatar, Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { Icon } from '../../components';
import { ProfileSettingsForm } from './components/ProfileSettingsForm';
import { ChangeAvatarModal } from '../../modals';

export const ProfileSettings: React.FC<PropsWithChildren> = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<Flex
			gap={'57px'}
			flexDirection={'column'}
			w={'100%'}
			h={'fit-content'}>
			<Flex alignItems={'center'} gap={'8px'}>
				<Icon name={'left-arrow'} width="24px" height="24px" />
				<Text variant={'B05'}>Profile Settings</Text>
			</Flex>
			<Flex
				flexDirection={'column'}
				width={'422px'}
				margin={' 0 auto'}
				alignItems={'center'}
				gap={'40px'}>
				<Box position={'relative'}>
					<Avatar
						w={'200px'}
						h={'200px'}
						name="Christian Nwamba"
						src="https://bit.ly/code-beast"
					/>
					<Flex
						h={'48px'}
						w={'48px'}
						position={'absolute'}
						bottom={0}
						right={0}
						justifyContent={'center'}
						alignItems={'center'}
						borderRadius={'50%'}
						border={'2px solid #175D00'}
						backgroundColor={'light'}
						cursor={'pointer'}
						onClick={onOpen}>
						<Icon name={'big-pen'} width="32px" height="32px" />
					</Flex>
				</Box>
				<ProfileSettingsForm />
			</Flex>
			<ChangeAvatarModal isOpen={isOpen} onClose={onClose} />
		</Flex>
	);
};
