import React from 'react';
import {
	Button,
	FormControl,
	FormControlProps,
	FormLabel,
	InputGroup,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
} from '@chakra-ui/react';
import { inputStyles } from '../../constants/input-styles';
import { labelStyles } from '../../constants';
import { ErrorMessage } from '../InputText/supplements';
import { Icon } from '../Icon/Icon';
import { colors } from '../../theme/colors';
interface Option {
	label: string;
	value: string;
}

interface ISelectProps {
	label?: string;
	options: Option[];
	errorMsg?: string;
	formControlProps?: FormControlProps;
	placeholder: string;
	value?: string;
	onChange?: (value: string) => void;
	maxH?: string;
	zIndex?: number;
	handleOpen?: () => void;
}

export const CustomSelect: React.FC<ISelectProps> = ({
	errorMsg,
	label,
	formControlProps,
	options,
	placeholder,
	value,
	maxH,
	zIndex = 2,
	handleOpen,
	onChange,
}) => {
	return (
		<FormControl
			isInvalid={!!errorMsg}
			{...formControlProps}
			zIndex={zIndex}>
			{label ? <FormLabel {...labelStyles}>{label}</FormLabel> : null}
			<InputGroup>
				<Menu>
					<MenuButton
						onClick={handleOpen}
						as={Button}
						rightIcon={
							<Icon
								name="down-arrow"
								width={'12px'}
								height={'12px'}
								color={`${colors.primary}`}
							/>
						}
						width={'100%'}
						textAlign="left"
						{...inputStyles}>
						{value
							? options.find(option => option.value === value)
								?.label
							: placeholder}
					</MenuButton>
					<MenuList
						maxH={maxH}
						overflow={'auto'}
						bg="white"
						minW="422px"
						border="1px solid #175D00"
						px={'8px'}>
						{options.map((option, index) => (
							<MenuItem
								key={index}
								value={option.value}
								onClick={() => onChange?.(option.value)}
								bg={
									value === option.value
										? `${colors.primary}`
										: 'white'
								}
								_hover={{
									bg: '#175d008b',
									borderRadius: '4px',
									color: 'white',
								}}>
								{option.label}
							</MenuItem>
						))}
					</MenuList>
				</Menu>
			</InputGroup>
			<ErrorMessage>{errorMsg}</ErrorMessage>
		</FormControl>
	);
};
