import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { ROUTE_PATH } from '../../../constants/route-paths';
import { Link } from 'react-router-dom';
import { InputPassword } from '../../../components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CreateNewPasswordFormValues } from '../types';

export const CreateNewPassword = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm<CreateNewPasswordFormValues>();

	const onSubmit: SubmitHandler<CreateNewPasswordFormValues> = data => {
		console.log(data);
	};
	const watchFields = watch(['password', 'confirmPassword']);
	const isDisabled = !watchFields.every(field => field);

	return (
		<Flex
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'center'}
			gap={'40px'}
			width={'422px'}>
			<Text color={'dark'} variant={'B04'} letterSpacing={'-1px'}>
				Create new password
			</Text>
			<VStack width={'100%'}>
				<InputPassword
					errorMsg={errors.password?.message as string}
					{...register('password', {
						required: 'Password is required',
					})}
					label={'New password'}
					variant={'flushed'}
					placeholder={'Enter your new password'}
					showRules={false}></InputPassword>
				<InputPassword
					errorMsg={errors.confirmPassword?.message as string}
					{...register('confirmPassword', {
						required: 'Confirm password is required',
						validate: value =>
							value === watchFields[0] ||
							'Passwords do not match',
					})}
					label={'Repeat new password'}
					variant={'flushed'}
					placeholder={'Repeat your new password'}
					showRules={false}></InputPassword>
			</VStack>
			<Button
				variant={'primary'}
				isDisabled={isDisabled}
				onClick={handleSubmit(onSubmit)}>
				Send
			</Button>
			<Flex gap={'8px'}>
				<Text color={'dark'}>Don’t have an account?</Text>
				<Link to={ROUTE_PATH.sign_up}>
					<Text fontWeight={'700'}>Sign Up</Text>
				</Link>
			</Flex>
		</Flex>
	);
};
