import React, { PropsWithChildren } from 'react';
import { FormErrorMessage } from '@chakra-ui/react';

export const ErrorMessage: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<FormErrorMessage
			color="error"
			fontSize="12px"
			lineHeight="18px"
			fontWeight={400}>
			{children}
		</FormErrorMessage>
	);
};
