import React, { ReactNode, useState } from 'react';
import {
	Box,
	InputProps,
	FormControlProps,
	FormControl,
	FormLabel,
	InputGroup,
	Input,
	InputRightElement,
} from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { labelStyles } from '../../constants';
import { inputStyles } from '../../constants/input-styles';
import { PasswordRule, ErrorMessage } from './supplements';

interface IInputPasswordProps extends InputProps {
	label?: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	clearValues?: string[];
	showRules?: boolean;
	rightElement?: ReactNode;
	lengthRuleValid?: boolean;
	specificSymbolRuleValid?: boolean;
}

export const InputPassword = React.forwardRef<
	HTMLInputElement,
	IInputPasswordProps
>(
	(
		{
			errorMsg,
			label,
			formControlProps,
			showRules = true,
			rightElement,
			lengthRuleValid,
			specificSymbolRuleValid,
			...rest
		},
		ref,
	) => {
		const [showPassword, setShowPassword] = useState(false);
		return (
			<FormControl isInvalid={!!errorMsg} {...formControlProps}>
				{label ? <FormLabel {...labelStyles}>{label}</FormLabel> : null}
				<InputGroup>
					<Input
						w="full"
						height={{ base: '46px', md: '50px' }}
						{...inputStyles}
						type={showPassword ? 'text' : 'password'}
						pr="3rem"
						ref={ref}
						{...rest}
					/>
					{rightElement ? (
						<InputRightElement h={'100%'}>
							{rightElement}
						</InputRightElement>
					) : (
						<InputRightElement
							cursor={'pointer'}
							height={'100%'}
							onClick={() =>
								setShowPassword(prevState => !prevState)
							}>
							<Icon
								name={showPassword ? 'eye-slash' : 'eye'}
								width="16px"
								height="16px"
								color={'gray'}
							/>
						</InputRightElement>
					)}
				</InputGroup>
				<ErrorMessage>{errorMsg}</ErrorMessage>
				{showRules ? (
					<Box mt="8px">
						<PasswordRule isValid={lengthRuleValid}>
							8 characters minimum
						</PasswordRule>
						<PasswordRule isValid={specificSymbolRuleValid}>
							At least 1 number or specific symbol ($,@, !, #,)
						</PasswordRule>
					</Box>
				) : null}
			</FormControl>
		);
	},
);
InputPassword.displayName = 'InputPassword';
