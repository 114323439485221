import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SignInResponse, CurrentUser, RefreshResponse } from '../../types';

const initialState: SignInResponse = {
	token: { accessToken: '', accessTokenExpiration: '', refreshToken: '' },
	user: {
		id: 0,
		name: '',
		avatarUrl: '',
	},
};

const authReducer = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthStatus: (_state, action: PayloadAction<SignInResponse>) => {
			return action.payload;
		},
		refreshToken: (state, action: PayloadAction<RefreshResponse>) => {
			state.token = action.payload;
		},
		setAuthUser: (state, action: PayloadAction<CurrentUser>) => {
			state.user = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});

export const { logout, refreshToken } = authReducer.actions;

export default authReducer.reducer;
